import Overlay from 'toolkit/modules/overlay';

const $header = $('#mypage__header');

if ($header.is('[data-private-event]')) {
  const privateEventCheckOverlay = new Overlay($('#private_event_check_overlay'), {
    full_screen: true,
    triggers: [$('.join-button')]
  });

  privateEventCheckOverlay.$el.on('overlay.afterShow', function () {
    const link = privateEventCheckOverlay.$trigger.attr('href');
    if (link) {
      $('#private-overlay__registration-button').attr('href', link);
    }
  });

  privateEventCheckOverlay.$el.find('.cancel').on('click', function () {
    privateEventCheckOverlay.hide();
  });
}
