import Overlay from 'toolkit/modules/overlay';

let $visiblePhotoGalleryLi;
let $nextPhotoGalleryLi;
let $prevPhotoGalleryLi;

const Lightbox = {
  lightboxID: '#photo_overlay',
  photoID: '#photo_overlay_image',
  $gallery_container: $('#photo-gallery'),

  setup: function () {
    this.single_photo_overlay = new Overlay($(this.lightboxID), {
      full_screen: true
    });

    this.$single_photo_overlay_image_tag = $(this.photoID);

    // attach event listeners:
    this.$gallery_container.on('click', '.img-link', function (click) {
      click.preventDefault();
      console.log('showing photo overlay...');
      const $this = $(this);

      const fullSizeImageUrl = $this.attr('data-full-size-url');
      const caption = $this.attr('data-caption');
      $visiblePhotoGalleryLi = $this.closest('li');
      Lightbox.show_photo_overlay(fullSizeImageUrl, caption);
    });

    $('#show_next_photo').on('click', Lightbox.show_next_gallery_photo);
    $('#show_prev_photo').on('click', Lightbox.show_prev_gallery_photo);
    this.setup = true;
  },

  show_photo_overlay: function (url, caption) {
    $nextPhotoGalleryLi = $visiblePhotoGalleryLi.next('li:not(.add-photo)');
    $prevPhotoGalleryLi = $visiblePhotoGalleryLi.prev('li');

    if ($nextPhotoGalleryLi.length) {
      $('#show_next_photo').removeClass('hidden');
    } else {
      $('#show_next_photo').addClass('hidden');
    }

    if ($prevPhotoGalleryLi.length) {
      $('#show_prev_photo').removeClass('hidden');
    } else {
      $('#show_prev_photo').addClass('hidden');
    }

    const img = document.createElement('img');
    img.src = url;
    img.onload = function () {
      console.log(`sizing ${url} appropriately`);

      const { height } = img;
      const { width } = img;

      if (height > width) {
        Lightbox.$single_photo_overlay_image_tag.css({
          height: '100%',
          maxHeight: img.height,
          maxWidth: img.width,
          width: 'auto'
        });
      } else {
        Lightbox.$single_photo_overlay_image_tag.css({
          width: '100%',
          height: 'auto',
          maxWidth: img.width,
          maxHeight: img.height
        });
      }

      Lightbox.$single_photo_overlay_image_tag.attr('src', url);

      $('#photo_overlay_caption').html(caption);

      if (caption === '') {
        // not using 'hidden' because it will need to be visible if
        // the page is editable
        $('.caption-wrapper').addClass('empty');
      } else {
        $('.caption-wrapper').removeClass('empty');
      }

      Lightbox.single_photo_overlay.show();
    };
  },

  get_visible_photo_li: function () {
    return $visiblePhotoGalleryLi;
  },

  get_visible_photo_id: function () {
    return $visiblePhotoGalleryLi.attr('data-id');
  },

  show_next_gallery_photo: function () {
    console.log('show_next_gallery_photo');
    if ($nextPhotoGalleryLi.length) {
      const $link = $nextPhotoGalleryLi.find('.img-link');
      const fullSizeImageUrl = $link.attr('data-full-size-url');
      const caption = $link.attr('data-caption');
      $visiblePhotoGalleryLi = $nextPhotoGalleryLi;
      Lightbox.show_photo_overlay(fullSizeImageUrl, caption);
    }
  },

  show_prev_gallery_photo: function () {
    console.log('show_prev_gallery_photo');
    if ($prevPhotoGalleryLi.length) {
      const $link = $prevPhotoGalleryLi.find('.img-link');
      const fullSizeImageUrl = $link.attr('data-full-size-url');
      const caption = $link.attr('data-caption');
      $visiblePhotoGalleryLi = $prevPhotoGalleryLi;
      Lightbox.show_photo_overlay(fullSizeImageUrl, caption);
    }
  }
};

export default Lightbox;
